import React from "react"
import { Link } from "gatsby"

const ServiceSidebar = ({ location }) => {
  const links = [
    { url: "/sd-architecture-governance", title: "Architecture Governance" },
    { url: "/sd-cicd-improvement", title: "CD/CD Improvement" },
    { url: "/sd-devops", title: "DevOps" },
    { url: "/sd-agile-mgm", title: "Agile Managment" },
    { url: "/sd-risk-mgm", title: "Risk Management" },
    { url: "/sd-release-mgm", title: "Test & Release Management" },
  ]

  // Get current pathname to mark active link on sidebar.
  const current_url = location.pathname ? location.pathname : ""

  return (
    <div className="services-details-info">
      <ul className="services-list">
        <li>
          {links.map(link => (
            <Link
              to={link.url}
              className={current_url === link.url ? "active" : ""}
            >
              {link.title}
            </Link>
          ))}
        </li>
      </ul>
    </div>
  )
}

export default ServiceSidebar
