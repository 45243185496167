import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ServiceDetails from "../components/ServiceDetails/ServiceGoverannce"

const Details = ({ location }) => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Service - Architecture Governance"
        homePageText="Home"
        homePageUrl="/"
        sectionPageText="Development Optimization"
        sectionPageUrl="/services-processes"
        activePageText="Architecture Governance"
      />
      <ServiceDetails location={location} />
      <Footer />
    </Layout>
  )
}

export default Details
